import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, {
      animation: 80,
      dataIdAttr: "data-rule-id",
      onStart: () => {
        this.orderingAtStart = this.ordering();
      },
      onEnd: () => {
        // capture previous and current ordering, clear instance state
        let orderingAtStart = this.orderingAtStart;
        let orderingAtEnd = this.ordering();
        this.orderingAtStart = null;

        // fetch authenticity token
        let csrfToken = document.querySelector("meta[name=csrf-token]").content;

        // send request to server updating ordering
        let request = fetch("/rules/ordering", {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            "x-csrf-token": csrfToken,
          },
          body: JSON.stringify({
            ordering: {
              expected: orderingAtStart,
              new: orderingAtEnd,
            },
          }),
        });

        // disable the sortable instance while we wait for the request to complete
        this.sortable.option("disabled", true);

        // re-enable sortable on completion
        request.then(response => {
          this.sortable.option("disabled", false);

          if (response.ok) {
            // ok!
          } else {
            // ordering update failed, reset order to how it was to begin with
            this.sortable.sort(orderingAtStart);
          }
        })
      },
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  ordering() {
    let ids = [];

    for (let child of this.element.children) {
      ids.push(child.getAttribute("data-rule-id"));
    }

    return ids;
  }
}
